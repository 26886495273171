import request from "@/utils/request";

//新增地址
export const addAddr = (data = null) => {
  return request({
    url: "/wxapp/app/shop/p/address/addAddr",
    method: "post",
    data,
  });
};

//修改地址
export const changeAddr = (data = null) => {
  return request({
    url: "/wxapp/app/shop/p/address/updateAddr",
    method: "put",
    data,
  });
};

//获取省份列表
export const listByPid = (params) => {
  return request({
    url: "/wxapp/app/shop/p/area/listByPid",
    method: "get",
    params,
  });
};

//获取用户地址列表
export const getAddressList = (params) => {
  return request({
    url: "/wxapp/app/shop/p/address/list",
    method: "get",
    params,
  });
};

//获取用户地址列表
export const setAddr = (data = null) => {
  return request({
    url: `/wxapp/app/shop/p/address/defaultAddr/${data}`,
    method: "put",
    data,
  });
};

//删除订单用户地址
export const deleteAddr = (data = null) => {
  return request({
    url: `/wxapp/app/shop/p/address/deleteAddr/${data}`,
    method: "delete",
    data,
  });
};

