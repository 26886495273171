<template>
  <div class="addr-box">
    <div class="addr-top">
      <p style="font-weight: bold">收货地址</p>
      <el-button
        @click="dialogFormVisible = true"
        type="text"
        style="color: #a50009"
        >新增地址</el-button
      >
    </div>
    <el-dialog title="收货地址" :visible.sync="dialogFormVisible">
      <el-form :model="addrParam">
        <el-form-item label="收货人" :label-width="formLabelWidth">
          <el-input v-model="addrParam.receiver" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机" :label-width="formLabelWidth">
          <el-input v-model="addrParam.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮编" :label-width="formLabelWidth">
          <el-input v-model="addrParam.postCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" :label-width="formLabelWidth">
          <el-select
            ref="pidRef"
            v-model="pid"
            @change="getCityList"
            placeholder="请选择所在省份"
          >
            <el-option
              v-for="item in pidList"
              :label="item.areaName"
              :value="item.areaId"
            ></el-option>
          </el-select>
          <el-select
            ref="cityRef"
            v-model="city"
            @change="getAddrList"
            placeholder="请选择所在城市"
          >
            <el-option
              v-for="item in cityList"
              :label="item.areaName"
              :value="item.areaId"
            ></el-option>
          </el-select>
          <el-select ref="areaRef" v-model="area" placeholder="请选择所在地址">
            <el-option
              v-for="item in areaList"
              :label="item.areaName"
              :value="item.areaId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址" :label-width="formLabelWidth">
          <el-input v-model="addrParam.addr" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="newAdder">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改收获地址" :visible.sync="dialogChange">
      <el-form :model="changeForm">
        <el-form-item label="收货人" :label-width="formLabelWidth">
          <el-input v-model="changeForm.receiver" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机" :label-width="formLabelWidth">
          <el-input v-model="changeForm.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" :label-width="formLabelWidth">
          <el-select
            ref="changepidRef"
            v-model="pid"
            @change="getCityList"
            placeholder="请选择所在省份"
          >
            <el-option
              v-for="item in pidList"
              :label="item.areaName"
              :value="item.areaId"
            ></el-option>
          </el-select>
          <el-select
            ref="changecityRef"
            v-model="city"
            @change="getAddrList"
            placeholder="请选择所在城市"
          >
            <el-option
              v-for="item in cityList"
              :label="item.areaName"
              :value="item.areaId"
            ></el-option>
          </el-select>
          <el-select
            ref="changeareaRef"
            v-model="area"
            placeholder="请选择所在地址"
          >
            <el-option
              v-for="item in areaList"
              :label="item.areaName"
              :value="item.areaId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址" :label-width="formLabelWidth">
          <el-input v-model="changeForm.addr" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeAddr">确 定</el-button>
      </div>
    </el-dialog>
    <div class="oderbox-content">
      <el-row class="el-rowone">
        <el-col>
          <div class="grid-content bg-purple" style="display: flex;flex-wrap: wrap">
            <div
              v-for="(item, index) in addAddressList"
              :key="index"
              @click="changeActive(item, index)"
            >
              <el-card
                class="box-card"
                shadow="hover"
                :class="{ 'card-active': index === isActive }"
              >
                <div slot="header" class="clearfix">
                  <span style="font-weight: 500"
                    >{{ item.receiver }} ({{
                      item.mobile
                    }})</span
                  >
                  <el-button
                    style="float: right; padding: 3px 0; color: #a50009"
                    type="text"
                    v-show="isActive === index"
                    @click.once="handleSetAddr(item)"
                    >默认地址</el-button
                  >
                </div>
                <div class="text item">
                  <p>{{ item.province }}-{{ item.city }}-{{ item.area }}</p>
                  <p>{{ item.addr }}</p>
                  <el-button
                    style="padding: 13px 0; color: #a50009"
                    type="text"
                    v-show="isActive === index"
                    @click="handleChange(item)"
                    >修改</el-button
                  >
                  <el-button
                    style="float: right; padding: 13px 0; color: #a50009"
                    type="text"
                    v-show="isActive === index"
                    @click="handleDeleteAddr(item)"
                    >删除</el-button
                  >
                </div>
              </el-card>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  addAddr,
  getAddressList,
  listByPid,
  setAddr,
  deleteAddr,
  changeAddr
} from "@/api/address";
import { createOrder } from "@/api/all";
export default {
  data() {
    return {
      //区列表
      area: "",
      //城市列表
      city: "",
      //省份列表
      pid: "",
      //区列表
      areaList: [],
      //城市列表
      cityList: [],
      //省份列表
      pidList: [],
      //弹出表单
      dialogFormVisible: false,
      //修改表单
      dialogChange: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "70px",
      addrParam: {
        addr: "", //地址
        addrId: 0, //地址ID
        area: "", //区
        areaId: 0, //区ID
        city: "", //城市
        cityId: 0, //城市ID
        mobile: "", //手机
        postCode: "", // 邮编
        province: "", // 省份
        provinceId: 0, // 省ID
        receiver: "", // 收货人
      },
      addAddressList: [],
      //选中
      isActive: 0,
      changeForm: {
        addr: "", //地址
        addrId: 0, //地址ID
        area: "", //区
        areaId: 0, //区ID
        city: "", //城市
        cityId: 0, //城市ID
        mobile: "", //手机
        postCode: "", // 邮编
        province: "", // 省份
        provinceId: 0, // 省ID
        receiver: "", // 收货人
      },
      orderParam:{}
    };
  },
  created() {
    this.getPidList();
    this.getList();
    this.orderParam = this.$store.state.orderParam;
  },
  methods: {
    //获取地址列表
    getList() {
      getAddressList().then((res) => {
        this.addAddressList = res.data;
        this.orderParam.addrId = res.data[0].addrId
      });
    },
    //新增地址
    getAddr() {
      addAddr(this.addrParam).then((res) => {
        this.$message(res.msg);
        this.getList();
      });
    },
    //修改地址
    handleChangeAddr() {
      changeAddr(this.changeForm).then((res) => {
        this.$message(res.msg);
        this.getList();
      });
    },
    //获取省份列表
    getPidList() {
      listByPid({ pid: this.addrParam.provinceId }).then((res) => {
        this.pidList = res.data;
      });
    },
    //获取城市列表
    getCityList(item) {
      listByPid({ pid: item }).then((res) => {
        this.cityList = res.data;
      });
    },
    //获取地址列表
    getAddrList(item) {
      listByPid({ pid: item }).then((res) => {
        this.areaList = res.data;
      });
    },
    //新增地址
    newAdder() {
      this.addrParam.province = this.$refs.pidRef.selected.label;
      this.addrParam.provinceId = Number(this.$refs.pidRef.selected.value);

      this.addrParam.city = this.$refs.cityRef.selected.label;
      this.addrParam.cityId = Number(this.$refs.cityRef.selected.value);

      this.addrParam.area = this.$refs.areaRef.selected.label;
      this.addrParam.areaId = Number(this.$refs.areaRef.selected.value);

      this.dialogFormVisible = false;
      this.getAddr();
      this.getList();
      createOrder(this.orderParam).then((res) => {
        // console.log(res);
        this.isActive = 0;
      });
    },
    changeActive(item, index) {
      this.isActive = index;
      console.log(item);
      this.orderParam.addrId = item.addrId;
      createOrder(this.orderParam).then((res) => {
        // console.log(res);
      });
    },
    //设置默认地址
    handleSetAddr(item) {
      setAddr(item.addrId).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getList();
          this.isActive = 0;
        }
      });
    },
    //删除地址
    handleDeleteAddr(item) {
      deleteAddr(item.addrId).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getList();
        }
      });
    },
    //修改
    handleChange(item) {
      this.dialogChange = true;
      this.changeForm = item;
    },
    //
    changeAddr() {
      this.changeForm.province = this.$refs.changepidRef.selected.label;
      this.changeForm.provinceId = Number(
        this.$refs.changepidRef.selected.value
      );

      this.changeForm.city = this.$refs.changecityRef.selected.label;
      this.changeForm.cityId = Number(this.$refs.changecityRef.selected.value);

      this.changeForm.area = this.$refs.changeareaRef.selected.label;
      this.changeForm.areaId = Number(this.$refs.changeareaRef.selected.value);
      this.handleChangeAddr();
      this.dialogChange = false;
      this.changeForm = {}
    },
  },
};
</script>

<style scoped>
.addr-box {
  padding-bottom: 20px;
  border-bottom: 1px solid #e9e9e9;
}
/deep/ .el-input__inner:focus {
  border-color: #a50009 !important;
}
.el-button--primary {
  color: #fff;
  background-color: #a50009;
  border-color: #a50009;
}
.addr-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.el-rowone {
}
.text {
  font-size: 14px;
  padding: 5px 20px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 273px;
  height: 130px;
  margin-bottom: 10px;
  margin-right: 12px;
}

/deep/ .el-card__header {
  padding: 10px 20px;
  height: 40px;
}

.card-active {
  border-color: #a50009;
}
</style>
