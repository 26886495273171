<!--  -->
<template>
  <div>
    <Header />
    <Sidebar />
    <div class="main">
      <p class="title">结算页</p>
      <p class="check">请核对订单信息并支付下单</p>
      <div class="content">
        <addresscard />
        <p style="font-weight: bold; margin-bottom: 25px; margin-top: 25px">
          商品详情
        </p>
        <div class="oderbox-content">
          <el-row class="el-rowone">
            <el-col :span="15" style="margin-bottom: 20px">
              <div class="grid-content bg-purple" style="display: flex">
                <img style="width: 80px; height: 80px" :src="shop.pic" alt="" />
                <div>
                  <p class="content-title">{{ shop.prodName }}</p>
                  <p class="content-content">
                    {{ shop.brief }}
                  </p>
                  <p class="content-footer">
                    <!-- {{properties[0]}} &nbsp;&nbsp; {{properties[1]}} -->
                    商品数量：{{ this.$route.query.totalCount }} &nbsp;&nbsp;{{
                      commodity.body
                    }}
                  </p>
                </div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="grid-content bg-purple" style="line-height: 84px">
                <p
                  style="font-weight: bold; color: rgb(182, 0, 5); float: right"
                >
                  ¥{{ this.$route.query.actualTotal }}
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-show="isreferrer" class="oderbox-content">
          <el-row class="el-rowone">
            <el-col :span="10" style="display: flex">
              <span style="width: 65px; line-height: 79px; font-weight: bold"
                >推荐人:
              </span>
              <el-input
                style="margin-bottom: 20px; margin-top: 20px"
                v-model="referrer"
                placeholder="请输入推荐业务员"
              ></el-input>
            </el-col>
          </el-row>
        </div>
        <el-row
          :gutter="20"
          class="pay"
          style="border-bottom: 1px solid #eee"
          v-if="couponList.length != 0 && isCoupon"
        >
          <el-col :span="15">
            <div class="grid-content bg-purple" style="h">
              <p
                style="
                  font-weight: bold;
                  margin-bottom: 25px;
                  boder-bottom: 0px;
                "
              >
                优惠券
              </p>
              <div
                :class="[couponList.length > 12 ? 'coupon-box' : 'coupon-box3']"
              >
                <div
                  v-for="(item, index) in couponList"
                  :key="index"
                  class="coupon scroll-content"
                  @click="changeActive(item, index)"
                >
                  <el-card class="coupon-card" shadow="hover">
                    <div
                      class="card-top"
                      :class="{ 'card-top-active': index === isActive }"
                    >
                      <div class="card-info">
                        <p class="card-price">¥{{ item.amount }}</p>
                        <h5 v-if="item.type == 2">{{ item.params.summary }}</h5>
                        <p v-else>无需满减</p>
                      </div>
                      <p class="card-data">
                        {{ item.startTime.split(" ")[0] }} -
                        {{ item.endTime.split(" ")[0] }}
                      </p>
                    </div>
                    <div class="card-bottom">
                      <p>
                        <a class="card-span">限品类：{{ item.typeLabel }}</a>
                      </p>
                    </div>
                  </el-card>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row
          v-show="!isCoupon && mycouponList != 0"
          :gutter="20"
          class="pay"
          style="border-bottom: 1px solid #eee; height: 250px"
        >
          <el-col :span="15">
            <div class="grid-content bg-purple">
              <p
                style="
                  font-weight: bold;
                  margin-bottom: 25px;
                  boder-bottom: 0px;
                "
              >
                已使用优惠券
              </p>
              <div class="coupon-box2" ref="scrollX">
                <div
                  v-for="(item, index) in mycouponList"
                  :key="index"
                  class="coupon"
                >
                  <el-card class="coupon-card" shadow="hover">
                    <div class="card-top-active">
                      <div class="card-info">
                        <p class="card-price">¥{{ item.amount }}</p>
                        <h5>{{ item.params.summary }}</h5>
                      </div>
                      <p class="card-data">
                        {{ item.startTime.split(" ")[0] }} -
                        {{ item.endTime.split(" ")[0] }}
                      </p>
                    </div>
                    <div class="card-bottom">
                      <p>
                        <a class="card-span">限品类：{{ item.typeLabel }}</a>
                      </p>
                    </div>
                  </el-card>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row
          :gutter="20"
          class="pay"
          style="display: flex; flex-direction: row-reverse"
        >
          <el-button v-show="!isCheck" type="primary" @click="checkOrder"
            >确认订单</el-button
          >
        </el-row>
        <el-row :gutter="20" v-show="isCheck" class="pay">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <p
                style="
                  font-weight: bold;
                  margin-bottom: 25px;
                  boder-bottom: 0px;
                "
              >
                选择支付方式
              </p>
              <div style="position: relative" class="howpay">
                <p @click="wxPay">
                  微信支付
                  <img
                    v-show="!iswx"
                    style="
                      width: 12px;
                      height: 12px;
                      left: 35px;
                      position: relative;
                      bottom: -6px;
                      left: 33px;
                      width: 12px;
                      height: 12px;
                    "
                    src="@/assets/icon/路径 3@2x.png"
                    alt=""
                  />
                </p>
              </div>

              <div
                style="position: relative; margin-top: 10px"
                class="howalipay"
              >
                <p @click="aliPay">
                  支付宝
                  <img
                    v-if="!iswx"
                    style="
                      width: 12px;
                      height: 12px;
                      left: 35px;
                      position: relative;
                      bottom: -6px;
                      left: 40px;
                      width: 12px;
                      height: 12px;
                    "
                    src="@/assets/icon/路径 21@2x.png"
                    alt=""
                  />
                  <img
                    v-if="isali"
                    style="
                      width: 12px;
                      height: 12px;
                      left: 35px;
                      position: relative;
                      bottom: -6px;
                      left: 40px;
                      width: 12px;
                      height: 12px;
                    "
                    src="@/assets/icon/路径 23@2x.png"
                    alt=""
                  />
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="6" :offset="12">
            <div class="grid-content bg-purple lastprice">
              <p
                style="
                  margin-bottom: 25px;
                  float: right;
                  color: rgba(20, 20, 20, 1);
                "
              >
                已优惠：<span
                  style="color: #b60005; font-weight: bold; font-size: 14px"
                  >-¥{{ couponprice }}</span
                >
              </p>
              <p
                style="
                  margin-bottom: 25px;
                  float: right;
                  color: rgba(20, 20, 20, 1);
                "
              >
                应付款总额：<span
                  style="color: #b60005; font-weight: bold; font-size: 18px"
                  >¥{{ total }}</span
                >
              </p>
            </div>
          </el-col>
        </el-row>

        <el-dialog
          width="20%"
          title="微信支付"
          :visible.sync="dialogTableVisible"
          v-loading="true"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div class="wechat">
            <img
              style="width: 200px; height: 200px"
              v-bind:src="commodity.qrImgBase64"
              alt=""
            />
          </div>
        </el-dialog>

        <el-dialog width="20%" title="支付宝" :visible.sync="dialogali">
          <div class="grid-content bg-purple contentbox">
            <iframe
              style="width: 205px; height: 205px; border: medium none"
              :srcdoc="alibody"
            >
            </iframe>
          </div>
        </el-dialog>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import Footer from "../../components/Footer/index.vue";
import Sidebar from "../../components/Sidebar/index.vue";
import addresscard from "./c-cps/addresscard.vue";
import {
  payOrder,
  viewState,
  getDetail,
  payOrderAli,
  submitOrder,
  total,
  getorderDetail,
} from "@/api/all.js";
import { getAddressList } from "@/api/address";
export default {
  components: { Header, Footer, Sidebar, addresscard },
  data() {
    return {
      mycouponList: [],
      isCoupon: true,
      total: 0,
      dialogali: false,
      dialogTableVisible: false,
      couponprice: 0,
      isActive: true,
      iswx: false,
      isali: false,
      alibody: "",
      commodity: {
        amount: 0,
        amountUnit: "",
        body: "",
        nonceStr: "",
        packageStr: "",
        paySign: "",
        prepayId: "",
        qrImgBase64: "",
        signType: "",
        timeStamp: "",
      },
      timer: null,
      order: null,
      shop: {
        prodName: null,
        brief: null,
        pic: null,
      },
      skuList: [],
      properties: [],
      couponList: [],
      payorder: {},
      isCheck: false,
      usecoupon: null,
      referrer: "",
      isreferrer: true,
    };
  },
  created() {
    getDetail({ prodId: this.$route.query.prodId }).then((res) => {
      this.shop = res.data;
      this.skuList = res.data.skuList[0];
      this.properties = res.data.skuList[0].properties.split(";");
    });
    this.couponList = this.$store.state.couponList;
    // this.wxPay();
    if (this.$route.query.orderNumbers) {
      this.continuetopay();
    }
  },
  methods: {
    continuetopay() {
      this.isCoupon = false;
      this.isCheck = true;
      this.payorder = this.$route.query;
      this.$store.commit("submitOrderParam", this.payorder);
      getorderDetail({ orderNumber: this.$route.query.orderNumbers }).then(
        (res) => {
          console.log(res, "订单详情");
          this.couponList = res.data.couponList;
          this.total = res.data.actualTotal;
          if (res.data.couponList[0]) {
            this.couponprice = res.data.couponList[0].amount;
          } else {
            this.couponprice = 0;
          }
        }
      );
      this.timer = setInterval(() => {
        // console.log('开始监听用户是否支付');
        viewState({ orderNumber: this.$route.query.orderNumbers }).then(
          (res) => {
            if (res.data === 1) {
              this.$router.push({
                path: "/success",
                query: {
                  orderNumber: this.payorder.orderNumbers,
                },
              });
            }
          }
        );
      }, 2000);
    },
    checkOrder() {
      getAddressList().then((res) => {
        console.log(res);
        if (res.data.length === 0) {
          this.$message.error("请选择收货地址");
        } else {
          this.isCoupon = false;
          this.gettotal();
          this.isCheck = true;
          console.log(this.$store.getters.getOrderParam);
          let order = this.$store.getters.getOrderParam;
          order.referrer = this.referrer;
          submitOrder(order).then((res) => {
            this.isreferrer = false;
            getorderDetail({ orderNumber: res.data.orderNumbers }).then(
              (res) => {
                this.mycouponList = res.data.couponList;
              }
            );
            this.payorder = {
              prodId: this.$route.query.proId,
              totalCount: this.$route.query.totalCount,
              orderNumbers: res.data.orderNumbers,
            };
            this.timer = setInterval(() => {
              // console.log('开始监听用户是否支付');
              viewState({ orderNumber: res.data.orderNumbers }).then((res) => {
                if (res.data === 1) {
                  this.$router.push({
                    path: "/success",
                    query: {
                      orderNumber: this.payorder.orderNumbers,
                    },
                  });
                }
              });
            }, 2000);
          });
        }
      });
    },
    gettotal() {
      total(this.$store.state.submitOrderParam).then((res) => {
        console.log(res);
        this.total = res.data.actualTotalAfter;
      });
    },
    changeActive(item, index) {
      this.couponprice = item.amount;
      this.isActive = index;
      this.$store.commit("setCouponId", [item.couponPeopleId]);
    },
    wxPay() {
      this.isCoupon = false;
      this.dialogTableVisible = true;
      this.iswx = true;
      payOrder(this.payorder).then((res) => {
        this.commodity.amount = res.data.amount;
        this.commodity.amountUnit = res.data.amountUnit;
        this.commodity.body = res.data.body;
        this.commodity.nonceStr = res.data.nonceStr;
        this.commodity.packageStr = res.data.packageStr;
        this.commodity.paySign = res.data.paySign;
        this.commodity.prepayId = res.data.prepayId;
        this.commodity.qrImgBase64 = res.data.qrImgBase64;
        this.commodity.signType = res.data.signType;
        this.commodity.timeStamp = res.data.timeStamp;
      });
    },
    aliPay() {
      this.isCoupon = false;
      this.dialogali = true;
      payOrderAli(this.payorder).then((res) => {
        this.isali = false;
        this.alibody = res.data.body;
        console.log(res.data.body, "阿里");
      });
    },
    //清除支付定时器
    clearWx() {
      this.$once("hook:beforeDestroy", () => {
        console.log("结束监听");
        clearInterval(this.timer);
      });
    },
  },
  beforeDestroy() {
    this.clearWx();
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style scoped>
/*.card-active {*/
/*	border: 1px solid #59acff;*/
/*}*/
/*/deep/ .is-hover-shadow {*/
/*	border: 1px solid #59acff !important;*/
/*}*/
.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
}

.scroll-content {
  display: inline-block;
  /* 宽度至少要大于容器的宽度，以便触发横向滚动条 */
}
.el-button--primary {
  color: #fff;
  background-color: #a50009;
  border-color: #a50009;
}
.lastprice {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
/*.coupon-card{*/
/*  width: 200px;*/
/*  height: 150px;*/
/*  margin-right: 10px;*/
/*  background: url("~@/assets/img/card.png") no-repeat;*/
/*  background-size: 200px 75px;*/
/*}*/
.coupon-box {
  display: flex;
  width: 1171px;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 400px;
  /*-webkit-overflow-scrolling: touch;*/
  /*scrollbar-width: none; !* 隐藏滚动条 *!*/
  /*-ms-overflow-style: none; !* 隐藏滚动条 *!*/
}
.coupon-box3 {
  display: flex;
  width: 1171px;
  flex-wrap: wrap;
}
.coupon-box2 {
  display: flex;
  width: 1171px;
}
.content-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.contentbox > p {
  padding: 23px 0;
}

.wechat {
  margin: auto;
  width: 205px;
  height: 205px;
  /*border: 1px solid rgba(4, 193, 95, 1);*/
}

.contentbox {
  height: 200px !important;
  text-align: center;
}

.howpay {
  width: 144px;
  height: 30px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 2px solid #04c15f;
  color: #04c15f;
  text-align: center;
  line-height: 28px;
}

.howalipay {
  width: 144px;
  height: 30px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 2px solid #1677ff;
  color: #1677ff;
  text-align: center;
  line-height: 28px;
}

.nonepay {
  width: 144px;
  height: 30px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 2px solid #cacaca;
  color: #cacaca;
  text-align: center;
  line-height: 28px;
}

.pay {
  padding: 23px 0;
}

.el-rowone {
  border-bottom: 1px solid #e9e9e9;
}

.content-footer {
  font-size: 12px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  padding: 0 0 8px 15px;
}

.content-content {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  padding: 0 0 20px 15px;
}

.content-title {
  font-size: 14px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #141414;
  padding: 0 0 8px 15px;
}

/*.grid-content {*/
/*  height: 121px;*/
/*}*/

/*.oderbox-content {*/
/*  height: 121px;*/
/*}*/

.main {
  min-height: 666px;
  padding-bottom: 60px;
  border-bottom: 1px solid #eee;
  width: 1220px;
  margin: 0 auto;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
}

.title {
  padding: 46px 0;
  font-size: 24px;
  font-weight: bold;
  color: #141414;
}

.check {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 16px;
}

.content {
  /*height: 1000px;*/
  padding: 20px 20px;
  width: 100%;
  border: 1px solid #e9e9e9;
}
/deep/ .el-card__body {
  padding: 0 !important;
}
.coupon {
  display: flex;
  padding-right: 12px;
  padding-bottom: 10px;
}
.coupon-card {
  width: 275px;
  height: 140px;
}
.card-top {
  padding-top: 20px;
  height: 100px;
  background: url("~@/assets/img/littlecard.png") no-repeat;
  background-size: 300px 100px;
}
.card-top-active {
  padding-top: 20px;
  height: 100px;
  background: url("~@/assets/img/littlecardimg.png") no-repeat;
  background-size: 300px 100px;
}

.card-top-active > .card-info {
  color: #ffffff;
}
.card-top-active > .card-data {
  color: #2990d1;
}

/deep/ .el-input__inner:focus {
  border-color: #a50009 !important;
}

.card-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #a5a5a5;
}
.card-price {
  font-size: 30px;
  font-weight: bold;
}
.card-data {
  padding-left: 30px;
  color: #a5a5a5;
}
.card-bottom {
  padding-left: 30px;
  padding-top: 10px;
}
.card-span {
  font-size: 6px;
  color: #b3b3b3;
}
</style>
